<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <div style="width:76%;float:left;margin-bottom:10px">
        <el-input size="small" placeholder="公司名称" v-model="listQuery.name" style="width:150px" @keyup.enter="getList"></el-input>
        <el-button size="small" type="primary" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 15px;width:400px" class="styleForm">
            <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">   
              <selecttaxtype v-model:type="listQuery.type" @success="getList()"></selecttaxtype>
            </el-form-item>
            <el-form-item label="选择班级 ：" :label-width="formLabelWidth" v-if="this.userRoleName != '学生' && tabName != 'seventh'">   
              <selectClass v-model:deptId="listQuery.deptId" @success="getUserList"></selectClass>
            </el-form-item>
            <el-form-item label="选择人员：" :label-width="formLabelWidth"  v-if="this.userRoleName != '学生' && tabName != 'seventh'">
              <selectuser ref="selectuser" code="bookkeeping" eduCode="" v-model:userId="listQuery.userIds" @success="getUser"></selectuser>
            </el-form-item>
            <el-form-item label="所属税务总局 ：" :label-width="formLabelWidth"> 
              <selectcity v-model:citys="listQuery.districtCode" @success="getList()"></selectcity>
            </el-form-item>
          </el-form>
        </search>
      </div>

      <!-- <div style="width:20%;text-align:right;float:right">
        <el-button size="small" type="primary" @click="customerExport"   >
          <el-icon><FolderOpened /></el-icon><span  > 导出</span>
        </el-button>
      </div> -->
  <!-- <qzf-video vid="4467be5374c7b6d07c9fe43862da5c6a_4"></qzf-video> -->

      <el-tab-pane label="我的申报" name="seventh">
        <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'seventh'" :listQuery="listQuery"></contentTable>
      </el-tab-pane>
      <el-tab-pane label="学生申报" name="sixth" v-if="this.userRoleName != '学生'">
        <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'sixth'" :listQuery="listQuery"></contentTable>
        </el-tab-pane>
      <!-- <el-tab-pane label="不可申报" name="eighth">
        <contentTable ref="clientList" :tabName="tabName" v-if="tabName == 'eighth'" :listQuery="listQuery"></contentTable>
      </el-tab-pane> -->
    </el-tabs>

    
  </div>
</template>
<script>
import selectClass from "@/components/Screening/selectClass";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import selectcity from "@/components/Screening/selectcity";
import { defineComponent, ref, onBeforeUnmount } from 'vue'
import contentTable from "./components/contentTable.vue";
import { assignCustomers } from "@/api/printSet"

export default {
  name: 'bookkeeping',
  components:{
    selecttaxtype,
    selectcity,
    contentTable,
    selectClass
  },

  mounted () {
    const unWatch = this.$watch("$route", (newVal, oldVal) => {
      // console.log(newVal, oldVal);
    })
    // unWatch()
  },
  data() {
    return {
      period: '202202',
      formLabelWidth: '120px',
      listQuery: {
        page: 1,
        limit: 20,
        accountingType:2,
        status:'2',
        userIds:[this.$store.getters['user/user'].userId]
      },
      userTotal: 2,
      activeName: "seventh",
      tabName:'seventh',
      userRoleName:this.$store.getters['user/user'].userRoleName

    }
  },
  created () {
    this.initBus()
  },
  methods: {
    initBus(){
      this.$bus.off("bookkeepingUpdate")
      this.$bus.on("bookkeepingUpdate", (val) => {
        this.getList()
      });
    },
    getList(){
      this.$refs.clientList.getList()
    },
    redirection() {
      this.$store.dispatch('tagsView/delCachedViewByName', "redirection")
      this.$router.push({
        path: "../../company/redirection.vue",
        name: "redirection"
      });
    },
    handleClick(tab, event) {
      this.listQuery.page = 1;
      this.listQuery.limit = 20;
      this.listQuery.userIds = []
      if (tab.props.label == "学生申报") {
        this.tabName = tab.props.name
        this.listQuery.accountingType = 1
      } else if (tab.props.label == "我的申报") {
        this.tabName = tab.props.name
        this.listQuery.userIds = [this.$store.getters['user/user'].userId]
        this.listQuery.accountingType = 2
      } else if (tab.props.label == "不可申报") {
        this.tabName = tab.props.name
        this.listQuery.accountingType = 3
      }
    },
    customerExport(){
      let param = {
        query:this.listQuery
      }
      assignCustomers(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    getUserList(){
      this.$refs.selectuser.init(this.listQuery.deptId)
    },
    getUser(e){
      this.listQuery.userIds = []
      if(this.listQuery.userIds.length == 0){
        e.map(v => {
          this.listQuery.userIds.push(v.id)
        })
      } 
    }
  },
  activated () {
    
  },
  setup() {
    onBeforeUnmount(()=>{
      console.log("2销毁")
    })
  },
}
</script>
<style lang="scss" scoped>
.home{
  padding: 10px;
  .header{
    margin-bottom: 10px;
    .right{
      float: right;
    }
    .input-search{
      width: 180px;
      margin-right: 5px;
    }
  }
  .img-ewm{
    width: 50px;
  }
}
.pagination{
  text-align: right;
  margin-right: 26px;
  margin-top: 16px;
}
 
.styleForm{
  .el-select{
    margin-left: 0px;
  }
}
</style>